// NPM Requirements
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Switch, Redirect, Route } from 'react-router-dom';

import { clearError } from '../actions/errorActions';
import { isMobile } from '../modules/helpers';

import '../../../public/static/stylesheets/fonts.css';
import '../../stylesheets/public.postcss';

// Components
import { Footer, Search, Support, BlogPost, Blog, Truck, Location, Home, Services, OfficeParks, Residential, Breweries, Privacy, Terms, Error404Page, Error500Page, Catering, WeddingCatering, CorporateCatering, CommunityEvents, CateringRegister, Login, ChooseAccount, Register, Vendors, DessertCatering, BurgerCatering, AsianCatering, TacoCatering, BbqCatering, PizzaCatering, DeletionStatus, ForgotPassword } from './Layout.imports-loadable';
import Header from './public/Header';
import Meta from './shared/Meta';
import ErrorModal from './shared/ErrorModal';

class Layout extends Component {
  constructor(props) {
    super(props);
    this.isMobile = isMobile();
  }

  componentDidMount() {
    document.documentElement.classList.add('public-layout');
    document.documentElement.classList.remove('admin-layout');
  }

  componentDidUpdate(prevProps, prevState) {
    const { errors } = this.props;
    const { pathname: currentPath } = this.props.location;
    const { pathname: prevPath } = prevProps.location;

    if (prevPath !== currentPath) {
      if (errors.status) {
        this.props.clearError();
      }
    }
  }

  render() {
    const { errors, location } = this.props;

    let showPage = true;
    if (errors.status && !errors.modal) {
      showPage = false;
    }

    return (
      <div className="public-root">
        <Meta />
        <Header
          history={this.props.history}
          location={this.props.location}
        />
        <div className="MainContent">
          {(errors.status === 500 && !errors.modal) || (errors.status === 422 && !errors.modal) &&
            <Error500Page />
          }

          {(errors.status === 404 && !errors.modal) &&
            <Error404Page />
          }

          {showPage &&
            <Switch>
              <Route
                path="/search/:id"
                component={Search}
              />
              <Route
                path="/search"
                component={Search}
              />
              <Route
                path="/food-trucks/:id"
                component={Truck}
              />
              <Redirect
                path="/food-trucks"
                to="/search"
              />
              <Route
                path="/blog/:id"
                component={BlogPost}
              />
              <Route
                path="/blog"
                component={Blog}
              />
              <Route
                path="/schedule/:id"
                component={Location}
              />
              <Route
                path="/services/office-parks"
                component={OfficeParks}
              />
              <Route
                path="/services/residential"
                component={Residential}
              />
              <Route
                path="/services/breweries"
                component={Breweries}
              />
              <Route
                path="/services"
                component={Services}
              />
              <Route
                path="/privacy-policy"
                component={Privacy}
              />
              <Route
                path="/terms-and-conditions"
                component={Terms}
              />
              <Route
                path="/deletion-status"
                component={DeletionStatus}
              />
              <Route
                path="/catering/register"
                component={CateringRegister}
              />
              <Route
                path="/food-truck-catering/wedding-catering"
                component={WeddingCatering}
              />
              <Route
                path="/food-truck-catering/corporate-catering"
                component={CorporateCatering}
              />
              <Route
                path="/food-truck-catering/dessert-catering"
                component={DessertCatering}
              />
              <Route
                path="/food-truck-catering/burger-catering"
                component={BurgerCatering}
              />
              <Route
                path="/food-truck-catering/asian-catering"
                component={AsianCatering}
              />
              <Route
                path="/food-truck-catering/taco-catering"
                component={TacoCatering}
              />
              <Route
                path="/food-truck-catering/bbq-catering"
                component={BbqCatering}
              />
              <Route
                path="/food-truck-catering/pizza-catering"
                component={PizzaCatering}
              />
              <Route
                path="/food-truck-catering/community-events"
                component={CommunityEvents}
              />
              <Route
                path="/catering"
                component={Catering}
              />
              <Route
                path="/forgot-password"
                component={ForgotPassword}
              />
              <Route
                path="/login"
                history={this.props.history}
                component={Login}
              />
              <Route
                path="/choose-account"
                component={ChooseAccount}
              />
              <Route
                path="/vendor/register"
                component={Register}
              />
              <Route
                path="/vendors"
                component={Vendors}
              />
              <Route
                path="/support"
                component={Support}
              />
              <Route
                exact
                path="/error"
                component={Error500Page}
              />
              <Route
                exact
                path="/not-found"
                component={Error404Page}
              />
              <Route
                exact
                path="/"
                component={Home}
              />
              <Route
                component={Error404Page}
              />
            </Switch>
          }
          <ErrorModal />
        </div>
        <Footer
          pathname={location.pathname}
          isMobile={this.isMobile}
        />
      </div>
    );
  }
}

Layout.propTypes = {
  children: PropTypes.object,
  clearError: PropTypes.func,
  history: PropTypes.object,
  location: PropTypes.object,
};

function mapStateToProps(state, props) {
  return {
    errors: state.errors
  };
}

function mapDispatchToProps(dispatch) {
  return {
    clearError() {
      dispatch(clearError());
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
